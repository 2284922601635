import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { getContractsAsync } from "src/api/contract/contract-api";
import { changeStatusText, MemberContractModel } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";
import usePostMessage from "src/pages/hooks/post-message";
import { useMemberInfoState } from "src/recoil/member/hook";
import { getUseTimeDiff } from "src/utils/common-util";

/**
 * 마이페이지 메인 > 신청/계약 내역 > 목록 화면
 */
const ContractList = () => {
  const fetchContractsRef = useRef<boolean>(false);

  const { goBack } = useNavigateGoBack();
  const { postMessageGoBackNavigation } = usePostMessage();
  const navigate = usePartnerNavigate();

  // 로그인한 회원 정보
  const { member } = useMemberInfoState();

  // 신청/계약 목록 조회 api
  const { executeAsync: getContracts } = useApiOperation(getContractsAsync);

  // 신청/계약 목록
  const [contracts, setContracts] = useState<Array<MemberContractModel>>([]);

  // 신청/계약 목록 조회
  const fetchContracts = useCallback(
    async (memberNo: string) => {
      const { data } = await getContracts({
        memberNo,
      });
      if (data?.data?.content) {
        // 이용자 상태 "삭제"인 것 제외
        const filteredContracts = (data.data.content || []).filter((contract: MemberContractModel) => contract?.memberStatus !== "MEMBER_DELETE");

        setContracts(filteredContracts);
      }
      if (!fetchContractsRef.current) {
        fetchContractsRef.current = true;
      }
    },
    [getContracts],
  );

  useEffect(() => {
    if (member && member.memberNo) {
      // 신청/계약 목록 조회
      fetchContracts(member.memberNo);
    }
  }, [member, fetchContracts]);

  // 초대하기 활성 여부
  const isActiveInvite = useCallback((contract: MemberContractModel) => {
    // 계약체결/이용승인/이용중/해지접수 상태일때 초대하기 활성화
    if (["CONTRACT_ACCEPT", "USE_APPROVAL", "USE_PROGRESS", "TERMINATE_RECEIVED"].includes(contract?.contractStep || "")) {
      if (contract?.isContractor) {
        // 계약자는 출력
        return true;
      }
      if (contract?.isAdmin) {
        // 관리자의 경우 이용종료일까지만 출력
        return moment().isSameOrBefore(moment(contract?.contractEndTime));
      }
    } else {
      return false;
    }
  }, []);

  return (
    <>
      <MetaTag title="마이페이지" />
      <Header
        headerType="BACK"
        title="신청/계약"
        onClickBackButton={() => {
          if (!window.ReactNativeWebView) {
            navigate("/court/mypage/main");
          } else {
            postMessageGoBackNavigation();
          }
        }}
      />
      <div className="contract-page">
        <h1 className="base-title">신청/계약</h1>
        <article className="contract-history">
          {fetchContractsRef.current && contracts.length === 0 ? (
            <section className="contents-empty">
              <p>신청/계약 내역이 없어요.</p>
            </section>
          ) : (
            <section className="contents-area">
              {/* 신청/계약 목록 */}
              {contracts.map((contract: MemberContractModel, index: number) => (
                <div key={index} className="contents-card">
                  {/* 내가 계약자인 건들만 계약 상세 페이지로 연결 가능 */}
                  <div
                    className={`contents-card__title-area ${contract?.isContractor ? "cursor-pointer" : ""}`}
                    onClick={() => {
                      if (contract?.isContractor) {
                        // 계약 상세 화면으로 이동
                        navigate(`/court/mypage/contracts/${contract?.contractId}`);
                      }
                    }}
                  >
                    <div className="flex-center-between">
                      <p>{contract.spaceProductName}</p>
                      <div
                        className={`base-chip ${
                          ["USE_APPROVAL", "USE_PROGRESS", "TERMINATE_RECEIVED"].includes(contract?.contractStep || "") ? "" : "color-gray"
                        }`}
                      >
                        {changeStatusText(contract?.spaceProductType!, contract?.contractStep!)}
                      </div>
                    </div>
                    <div className="contents-card__date">
                      {/* F/O 타입의 경우 이용기간 표기 */}
                      {contract?.spaceProductType !== "TIME_COURT" && (
                        <span>
                          {contract?.contractStartTime && moment(contract?.contractStartTime).format("YYYY-MM-DD")} ~{" "}
                          {contract?.contractEndTime && moment(contract?.contractEndTime).format("YYYY-MM-DD")}
                        </span>
                      )}
                      {/* T 타입의 경우 이용시간 표기 */}
                      {contract?.spaceProductType === "TIME_COURT" && (
                        <span>
                          {contract?.contractStartTime && moment(contract?.contractStartTime).format("YYYY-MM-DD HH:mm")}~
                          {contract?.contractEndTime && moment(contract?.contractEndTime).format("HH:mm")}{" "}
                          {getUseTimeDiff(contract?.contractStartTime, contract?.contractEndTime)}
                        </span>
                      )}

                      {/* 내가 계약자인 건들만 계약 상세 페이지로 연결 가능 */}
                      {contract?.isContractor && <button type="button" />}
                    </div>
                  </div>
                  {/* F/O 타입이고, 계약예정, 계약체결, 이용승인, 이용중, 이용완료, 해지접수, 해지완료 상태일때 노출 (계약예정이후) */}
                  {contract?.spaceProductType !== "TIME_COURT" &&
                    !["APPLY_RECEIVED", "APPLY_CANCEL", "APPLY_CONFIRM"].includes(contract?.contractStep || "") && (
                      <div className="contents-card__info-area">
                        <p>
                          이용자 {contract?.memberTotCount || 0} / {contract?.memberMaxNums || 0} 명
                        </p>
                        {/* 
                          계약체결/이용승인/이용중/해지접수 상태일때 초대하기 활성화 
                          관리자의 경우 이용종료일까지만 초대하기 활성화
                        */}
                        {isActiveInvite(contract) && (
                          <button
                            type="button"
                            onClick={() => {
                              // 이용자 목록 화면으로 이동
                              navigate(`/court/mypage/contracts/${contract.contractId}/users`);
                            }}
                          >
                            초대하기
                          </button>
                        )}
                      </div>
                    )}
                </div>
              ))}
            </section>
          )}
        </article>
      </div>
    </>
  );
};

export default ContractList;

import { debounce, orderBy } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { getContractAsync, getMemberContractManageListAsync, postMemberContractManageInviteAsync } from "src/api/contract/contract-api";
import { ContractModel, MemberContractManageListParams, MemberContractManageModel } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { usePartnerNavigate } from "src/api/hooks/usePartnerNavigate";
import { BaseButton, ConfirmModal } from "src/components";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import useIntersectionObserver from "src/pages/hooks/intersection-observer";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";
import { replaceNumberWithPlusMinus } from "src/utils/common-util";
import { InviteResult } from "../user-types";
import UserItem from "./components/UserItem";

const PAGE_SIZE = 20;

type Modal = {
  isOpen: boolean;
};

/**
 * 마이페이지 메인 > 신청/계약 내역 > 상세 > 이용자 목록
 */
const UserList = () => {
  const firstLoadUserListRef = useRef<boolean>(false);

  const { goBack } = useNavigateGoBack();

  const navigate = usePartnerNavigate();

  // path variable 계약 id
  const { contractId } = useParams();

  // 신청/계약 상세 조회 api
  const { executeAsync: getContract } = useApiOperation(getContractAsync);

  // 계약의 이용자 목록 조회 api
  const { executeAsync: getMemberContractManageList, loading: getMemberContractManageListLoading } =
    useApiOperation(getMemberContractManageListAsync);

  const { executeAsync: postMemberContractManageInvite } = useApiOperation(postMemberContractManageInviteAsync, { noHandleError: true });

  // 신청/계약 상세
  const [contract, setContract] = useState<ContractModel | null>(null);

  // 계약의 이용자 목록
  const [contractManages, setContractManages] = useState<Array<MemberContractManageModel>>([]);

  // 검색어
  const [searchText, setSearchText] = useState<string>("");

  // 현재 페이지
  const [page, setPage] = useState<number>(0);

  // 총 페이지
  const [totalPages, setTotalPages] = useState<number>(0);

  // 확인 버튼만 있는 알림 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });
  const [inviteConfirmModal, setInviteConfirmModal] = useState<Modal>({ isOpen: false });
  const [inviteSuccessModal, setInviteSuccessModal] = useState<Modal>({ isOpen: false });
  const [inviteFailModal, setInviteFailModal] = useState<Modal>({ isOpen: false });
  const [inviteResults, setInviteResults] = useState<Array<InviteResult>>([]);
  // const [accessLogModal, setAccessLogModal] = useState<Modal>({ isOpen: false });

  // const openAcessLogModal = () => setAccessLogModal({ isOpen: true });
  // const closeAcessLogModal = () => setAccessLogModal({ isOpen: false });
  // const handleClickAccessLogModalOkButton = () => closeAcessLogModal();
  // const handleClickAccessLogModalCancelButton = () => closeAcessLogModal();

  const [clickedPhoneNumber, setClickedPhoneNumber] = useState<string>();

  // 이용자 목록 조회
  const loadUserList = useCallback(
    async (_page: number, _searchText?: string) => {
      if (!contract?.contractManageId) return;

      let params: MemberContractManageListParams = {
        contractManageId: contract.contractManageId,
        pageRequest: {
          page: _page,
          size: PAGE_SIZE,
          sort: {
            orders: [
              {
                direction: "DESC",
                property: "isAdmin", // 마스터
              },
              {
                direction: "DESC",
                property: "contractMemberId",
              },
            ],
          },
        },
      };
      if (_searchText) {
        let searchValue = _searchText.split("-").join("");
        if (searchValue.startsWith("010")) {
          searchValue = searchValue.substring(1, searchValue.length);
        }
        params = Object.assign(params, { searchType: "MEMBER_MOBILE_NUMBER", searchValue });
      }
      const { data } = await getMemberContractManageList(params);
      setTotalPages(Number(data?.meta?.pageMeta?.totalPages || 0));

      // 마스터가 제일 먼저, 이후 id 정렬
      const sortedContractManages = orderBy(data?.data || [], ["isAdmin", "contractMemberId"], ["desc", "desc"]);
      return sortedContractManages;
    },
    [getMemberContractManageList, contract],
  );

  // 이용자 목록 검색
  const searchUserList = useCallback(
    async (_searchText?: string) => {
      const firstPage = 0;
      setPage(firstPage);
      const results = await loadUserList(firstPage, _searchText);
      setContractManages(results || []);

      if (!firstLoadUserListRef.current) {
        firstLoadUserListRef.current = true;
      }
    },
    [loadUserList],
  );

  // 이용자 목록 더 불러오기
  const loadMoreUserList = useCallback(async () => {
    if (contractManages.length > 0) {
      const nextPage = page + 1;
      setPage(nextPage);
      const results = await loadUserList(nextPage, searchText);
      setContractManages([...contractManages, ...(results || [])]);
    }
  }, [contractManages, page, loadUserList, searchText]);

  // 스크롤 감지
  const onIntersect: IntersectionObserverCallback = useCallback(
    ([entry]: IntersectionObserverEntry[]) => {
      // console.log("감지결과 :", entry.isIntersecting);
      if (entry.isIntersecting && !getMemberContractManageListLoading && page < totalPages) {
        loadMoreUserList();
      }
    },
    [getMemberContractManageListLoading, loadMoreUserList, page, totalPages],
  );

  // 교차 감지 hook (scroll 감지)
  const { setTarget } = useIntersectionObserver({
    onIntersect,
    threshold: [0],
  });

  // debounce 최적화
  const debouncedSearch = useMemo(
    () =>
      debounce((_searchText?: string) => {
        if (contract?.contractManageId) {
          searchUserList(_searchText);
        }
      }, 200),
    [contract, searchUserList],
  );

  // 신청/계약 상세 조회
  const fetchContract = useCallback(
    async (contractId: string) => {
      const { data } = await getContract({ contractId });
      const contract = data?.data || null;

      setContract(contract);
    },
    [getContract],
  );

  // 이용자 초대 버튼 클릭
  const clickUserInvite = useCallback(() => {
    if (Number(contract?.memberMaxNums || 0) <= Number(contract?.memberTotCount || 0)) {
      // 최대 이용자수 초과시 alert
      setAlertModal({ isOpen: true });
      return;
    }
    // 이용자 초대 화면으로 이동
    navigate(`/court/mypage/contracts/${contractId}/users/invite`);
  }, [navigate, contract, contractManages]);

  useEffect(() => {
    if (contractId) {
      // 신청/계약 상세 조회s
      fetchContract(contractId);
    }
  }, [contractId, fetchContract]);

  useEffect(() => {
    if (contract?.contractManageId) {
      // 계약의 이용자 목록 조회
      searchUserList();
    }
  }, [contract, searchUserList]);

  // 이용자 재초대
  const reInvite = async (phoneNumber: string) => {
    try {
      await postMemberContractManageInvite({
        contractManageId: contract?.contractManageId!,
        contractMemberList: [
          {
            inviteMobileNumber: phoneNumber,
          },
        ],
      });

      setInviteSuccessModal({ isOpen: true });
      setInviteResults([
        {
          type: "SUCCESS",
          phoneNumber: phoneNumber,
        },
      ]);

      searchUserList();
    } catch (error: any) {
      setInviteFailModal({ isOpen: true });

      console.log(error?.response?.data?.meta?.errorCode);
      setInviteResults([
        {
          type: "FAIL",
          phoneNumber: phoneNumber,
          failErrorCode: error?.response?.data?.meta?.errorCode,
        },
      ]);
    } finally {
      setInviteConfirmModal({ isOpen: false });
    }
  };

  // 리스트 클릭 - 이용자 재초대
  const clickUserItemList = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement;

    if (target.classList.contains("btn-reinvite")) {
      const phoneNumber = target.dataset.inviteMobileNumber;

      setClickedPhoneNumber(phoneNumber);
      setInviteConfirmModal({ isOpen: true });
    }
  };

  console.log(contract?.contractApplyNumber);

  const handleClickAceessLogExportButton = async () => {
    if (contractId === undefined) return;
    navigate(`/court/mypage/contracts/${contract?.contractManageId}/users/${contract?.contractApplyNumber}`);
  };

  return (
    <>
      <MetaTag title="마이페이지" />
      <Header
        headerType="BACK"
        title="이용자 목록"
        onClickBackButton={() => {
          goBack();
        }}
      />

      <div className="user-invite-page">
        <div className="base-title-wrap">
          <h1 className="base-title">이용자 목록</h1>
        </div>
        <div className="flex-center user-title-btn px25">
          <BaseButton onClick={handleClickAceessLogExportButton} title={"출입기록 보기"} className="base-btn size-small color-white mr8" />
          {searchText.length === 0 && (
            <button type="button" className="base-btn size-small" onClick={() => clickUserInvite()}>
              이용자 초대
            </button>
          )}
        </div>
        <section className="floating-input-area">
          <div className="base-input-wrap search-case">
            <input
              type="tel"
              inputMode="tel"
              pattern="[0-9\-]+"
              className="base-input"
              placeholder={"이용자의 휴대폰 번호를 입력하세요."}
              value={searchText}
              onBlur={(e) => {
                const targetValue = e.target?.value.trim();
                const replacedValue = replaceNumberWithPlusMinus(targetValue);
                setSearchText(replacedValue);
                debouncedSearch(replacedValue);
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.preventDefault();
                const targetValue = e.target?.value;
                const replacedValue = replaceNumberWithPlusMinus(targetValue);
                setSearchText(replacedValue);
                debouncedSearch(replacedValue);
              }}
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                e.preventDefault();
                if (e.key === "Enter") {
                  if (contract?.contractManageId) {
                    searchUserList(searchText);
                  }
                }
              }}
              maxLength={20}
            />

            {searchText.length > 0 ? (
              <button
                type="button"
                className="clear-btn"
                onClick={() => {
                  setSearchText("");
                  debouncedSearch();
                }}
              />
            ) : (
              <button
                type="button"
                className={`search-btn`}
                onClick={() => {
                  if (contract?.contractManageId) {
                    searchUserList();
                  }
                }}
              />
            )}
          </div>
        </section>
        <article className="user-invite-list">
          {firstLoadUserListRef.current && contractManages.length === 0 ? (
            <section className="contents-empty">
              <p>존재하지 않는 이용자입니다.</p>
            </section>
          ) : (
            <section className="contents-area" onClick={clickUserItemList}>
              {contractManages.map((contractManage: MemberContractManageModel, index: number) => (
                <UserItem key={index} contractManage={contractManage} fetchContract={fetchContract} setSearchText={setSearchText} />
              ))}

              {/* 마지막까지 스크롤되었는지 확인하기 위한 엘리먼트 */}
              <div ref={setTarget}></div>
            </section>
          )}
          {getMemberContractManageListLoading && (
            <section className="contents-indicator">
              <div className="--loading"></div>
            </section>
          )}
        </article>
        {/* {searchText.length === 0 && (
          <div className="base-floating-btn-wrap">
            <button type="button" className="base-btn" onClick={() => clickUserInvite()}>
              이용자 초대
            </button>
          </div>
        )} */}

        <ConfirmModal
          isOpen={alertModal.isOpen}
          btnRightTitle={"확인"}
          onClick={() => {
            setAlertModal({ isOpen: false });
          }}
        >
          <div>
            <p className="font18 font-weight-600">최대 이용자 수를 초과하였습니다.</p>
            <p className="mt20 font15 font-weight-400">이용자를 추가하시려면 등록된 이용자 삭제 후 다시 시도해 주세요.</p>
          </div>
        </ConfirmModal>

        <ConfirmModal
          isOpen={inviteConfirmModal.isOpen}
          btnLeftTitle={"취소"}
          btnRightTitle={"확인"}
          onClose={() => setInviteConfirmModal({ isOpen: false })}
          onClick={() => reInvite(clickedPhoneNumber as string)}
          className="pa20 pt30"
        >
          <div>
            <p className="font18 font-weight-600">초대할까요?</p>
            <p className="font15 font-weight-400 mt20">해당 이용자에게 앱 설치 알림톡이 발송됩니다.</p>
            <p className="font15 font-weight-400 mt5">회원 가입을 완료하면 공간 이용 권한이 부여됩니다.</p>
          </div>
        </ConfirmModal>

        <ConfirmModal
          isOpen={inviteSuccessModal.isOpen}
          btnRightTitle={"확인"}
          onClick={() =>
            setInviteSuccessModal({
              isOpen: false,
            })
          }
        >
          <div>
            <p className="font18 font-weight-600">초대를 완료했어요.</p>
          </div>
        </ConfirmModal>

        <ConfirmModal
          isOpen={inviteFailModal.isOpen}
          btnLeftTitle={"닫기"}
          btnRightTitle={"실패 사유 보기"}
          onClose={() => {
            setInviteFailModal({ isOpen: false });
          }}
          onClick={() => {
            navigate(`/court/mypage/contracts/${contractId}/users/invite/fail`, {
              state: {
                inviteResults,
              },
            });
          }}
        >
          <div>
            <p className="font18 font-weight-600">초대를 완료했어요.</p>
            <p className="mt20 font15 font-weight-400">
              성공 {inviteResults.filter((inviteResult) => inviteResult.type === "SUCCESS").length}건 / 실패{" "}
              {inviteResults.filter((inviteResult) => inviteResult.type === "FAIL").length}건
            </p>
          </div>
        </ConfirmModal>
        {/* {accessLogModal.isOpen && (
          <AccessLogModal
            onClick={handleClickAccessLogModalOkButton}
            onClose={handleClickAccessLogModalCancelButton}
            contractManageId={contract?.contractManageId!}
            contractApplyNumber={contract?.contractApplyNumber!}
          />
        )} */}
      </div>
    </>
  );
};
export default UserList;
